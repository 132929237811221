import styled from '@emotion/styled';
import type { SubscriptionSubmitHandler } from 'components';
import config from 'imdconfig';
import {
  useCurrentCustomerSubscription,
  useFetchEntity,
  useInvoice,
} from 'imddata';
import type { SubscriptionId } from 'imddata/types/entities';
import { useSubscriptionUpsell } from 'imdshared';
import {
  PageLayer,
  LoadingIndicator,
  BackButton,
  Button,
  Centered,
  LinkButton,
} from 'imdui';
import qs from 'qs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { PaymentFlow } from '../../Order/screens/PaymentGate/components/PaymentFlow';
import { CancelFlow } from './CancelFlow';
import { PaymentStatusMessage } from './PaymentStatusMessage';
import { loadLoggedUser } from 'imddata/actionTypes/users';
import { SubscriptionSelectionManager } from 'components/subscriptions/SubscriptionSelectionManager';
import { SubscriptionUpgrade } from 'components/subscriptions/SubscriptionUpgrade';
import { Divider, Header, Title } from './styles';

const ReloadProfile = ({ delay = 0 }: { delay: number }) => {
  const dispatch = useDispatch();
  const { refresh } = useFetchEntity({
    entity: 'pricesCustomer',
  });
  const timeout = useRef<number>();
  useEffect(() => {
    timeout.current = window.setTimeout(() => {
      dispatch(loadLoggedUser());
      refresh();
    }, delay);
    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, []);
  return null;
};

type State = {
  page: 'subscriptions' | 'payment' | 'thank-you' | 'downgrade' | 'cancel';
  orderId?: number | string;
  invoiceId?: number | string;
  subscriptionId?: string;
};

enum ActionTypes {
  RESTART = 'RESTART',
  START_PAYMENT = 'START_PAYMENT',
  DOWNGRADE = 'DOWNGRADE',
  CANCEL = 'CANCEL',
  FINISH_FLOW = 'FINISH_FLOW',
}

type Action =
  | {
      type: typeof ActionTypes.START_PAYMENT;
      orderId: number | string;
      subscriptionId: string;
    }
  | {
      type: typeof ActionTypes.RESTART;
    }
  | {
      type: typeof ActionTypes.CANCEL;
    }
  | {
      type: typeof ActionTypes.DOWNGRADE;
      subscriptionId: string;
    }
  | {
      type: typeof ActionTypes.FINISH_FLOW;
      invoiceId?: number;
      subscriptionId?: string;
    };

const initialState: State = {
  page: 'subscriptions',
};

type Reducer = (s: State, a: Action) => State;

const pageReducer: Reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.RESTART: {
      return initialState;
    }
    case ActionTypes.DOWNGRADE: {
      return {
        ...state,
        page: 'downgrade',
        subscriptionId: action.subscriptionId || state.subscriptionId,
      };
    }
    case ActionTypes.CANCEL: {
      return {
        ...state,
        page: 'cancel',
      };
    }
    case ActionTypes.FINISH_FLOW: {
      return {
        ...state,
        page: 'thank-you',
        invoiceId: action.invoiceId,
        subscriptionId: action.subscriptionId || state.subscriptionId,
      };
    }
    case ActionTypes.START_PAYMENT:
      return {
        ...state,
        orderId: action.orderId,
        page: 'payment',
        subscriptionId: action.subscriptionId,
      };
    default:
      return state;
  }
};

const FinishFlowContainer = styled.div`
  text-align: center;
`;

const TRIAL_SUBS: (SubscriptionId | 'free')[] = ['free', 'trial-tier-2'];

export const SubscribeLayer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const currentSubscription = useCurrentCustomerSubscription();
  const { isOpened, context, open, close } = useSubscriptionUpsell();

  const [{ page, orderId, invoiceId, subscriptionId }, dispatch] = useReducer(
    pageReducer,
    initialState
  );

  const { entry: invoice, request: invoiceRequest } = useInvoice({
    id: invoiceId,
    passive: !invoiceId,
  });

  const handleClose = useCallback(() => {
    close();
    dispatch({ type: ActionTypes.RESTART });
  }, [location]);

  const handleCloseCancellation = useCallback(
    (meta?: Record<string, string>) => {
      handleClose();
      if (window.analytics && meta) {
        window.analytics.track('FT abandoned subscription downgrade', meta);
      }
    },
    [handleClose]
  );

  const params = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }, [location.search]);

  useEffect(() => {
    if (params.openSubscribe) {
      open();
    }
  }, [params.openSubscribe]);

  useEffect(() => {
    if (params.invoiceId) {
      dispatch({
        type: ActionTypes.FINISH_FLOW,
        subscriptionId: params.subscriptionId as string,
        invoiceId: Number(params.invoiceId),
      });
    }
  }, [invoiceId]);

  const handleSubmitSubscription: SubscriptionSubmitHandler = useCallback(
    (p) => {
      switch (p.mode) {
        case 'paid':
        case 'paid_pro_rated':
        case 'paid_discounted':
          dispatch({
            type: ActionTypes.START_PAYMENT,
            orderId: p.orderId,
            subscriptionId: p.subscriptionId,
          });
          break;
        case 'instant':
        case 'instant_upgrade':
        case 'legacy_upgrade':
        case 'renew_to_different':
          dispatch({
            type: ActionTypes.FINISH_FLOW,
            subscriptionId: p.subscriptionId,
          });
          break;
        case 'downgrade':
          dispatch({
            type: ActionTypes.DOWNGRADE,
            subscriptionId: p.subscriptionId,
          });
          break;
        case 'cancel':
          dispatch({
            type: ActionTypes.CANCEL,
          });
      }
    },
    []
  );

  const content = useMemo(() => {
    if (page === 'downgrade' && currentSubscription && subscriptionId) {
      return (
        <CancelFlow
          downgradeToId={subscriptionId as SubscriptionId}
          onClose={handleCloseCancellation}
        />
      );
    }
    if (page === 'cancel' && currentSubscription) {
      return (
        <CancelFlow downgradeToId={null} onClose={handleCloseCancellation} />
      );
    }
    if (page === 'subscriptions') {
      return (
        <SubscriptionSelectionManager onSubmit={handleSubmitSubscription}>
          {context.trial ? (
            <SubscriptionUpgrade subscriptionIds={TRIAL_SUBS} />
          ) : context.subscriptionIds ? (
            <SubscriptionUpgrade
              recommendedSubscriptionId={context.recommendedSubscriptionId}
              subscriptionIds={context.subscriptionIds}
            />
          ) : (
            <SubscriptionUpgrade />
          )}
        </SubscriptionSelectionManager>
      );
    }
    if (page === 'payment') {
      if (!orderId) return null;
      return (
        <>
          <PaymentFlow
            id={Number(orderId)}
            entity="orders"
            makeReturnUrl={(id) =>
              `${config.imdfront.appUrl}${location.pathname}?openSubscribe=true&invoiceId=${id}&subscriptionId=${subscriptionId}`
            }
            onError={(_t, invoiceId) => {
              dispatch({ type: ActionTypes.FINISH_FLOW, invoiceId });
            }}
            onComplete={(invoiceId) => {
              dispatch({ type: ActionTypes.FINISH_FLOW, invoiceId });
            }}
            terms={
              <Trans
                i18nKey="subscription-terms"
                components={[
                  <a
                    key={1}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={t('subscriptions-terms-link') || '#'}
                  >
                    terms
                  </a>,
                ]}
                defaults={
                  'Paying this order you are accepting <0>terms and conditions</0> for subscription'
                }
              />
            }
            action={
              <LinkButton
                size="small"
                style={{ width: '100%', marginTop: '16px' }}
                text={t('have-voucher-apply-here')}
                to={`/order/overview?orderId=${orderId}`}
                onClick={() => {
                  close();
                }}
              />
            }
          ></PaymentFlow>
        </>
      );
    }
    if (currentSubscription?.subscriptionId === subscriptionId) {
      return (
        <FinishFlowContainer data-test-id="ThankYouPage">
          <ReloadProfile delay={0} />
          <PaymentStatusMessage
            success={true}
            message={t('subscription-is-activated')}
          />
          <Button
            onClick={handleClose}
            testId="SubscribeLayer-continue"
            text={t('continue')}
            primary={true}
          />
        </FinishFlowContainer>
      );
    }

    if (
      currentSubscription?.subscriptionId !== subscriptionId &&
      (!invoice || invoice.status === 'paid')
    ) {
      return (
        <Centered>
          <ReloadProfile delay={5000} />
          <LoadingIndicator
            style={{ justifySelf: 'center', alignSelf: 'center' }}
            size="large"
          />
        </Centered>
      );
    }
    if (
      (invoiceId && invoice && invoice.status !== 'paid') ||
      invoiceRequest.failed
    ) {
      return (
        <FinishFlowContainer>
          <PaymentStatusMessage success={false} message={t('payment-failed')} />
          <Button
            onClick={() => {
              dispatch({ type: ActionTypes.RESTART });
              history.push('/account/invoices');
            }}
            text={t('view-invoices')}
            primary={true}
          />
        </FinishFlowContainer>
      );
    }
    return;
  }, [
    context?.trial,
    context?.subscriptionIds,
    context?.recommendedSubscriptionId,
    orderId,
    page,
    currentSubscription?.subscriptionId,
    invoiceId,
    invoice?.status,
    invoiceRequest.failed,
    subscriptionId,
  ]);

  return (
    <PageLayer
      isOpen={isOpened}
      lockClickOutside={true}
      close={page !== 'payment' ? handleClose : undefined}
      title={
        <>
          {page === 'payment' && (
            <BackButton
              onClick={() => {
                dispatch({ type: ActionTypes.RESTART });
              }}
            />
          )}
          {page === 'subscriptions' && (
            <>
              <Header style={{ width: '100%', marginLeft: '32px' }}>
                <Title>{t('subscribe')}</Title>
              </Header>
              <Divider />
            </>
          )}
        </>
      }
    >
      {content}
    </PageLayer>
  );
};
