import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import type { InjectedFormProps } from 'redux-form';
import {
  // @ts-ignore
  defaultShouldError,
  formValueSelector,
  FieldArray,
  reduxForm,
} from 'redux-form';
import type { EntityModels, ReduxState, TrackNested } from 'imddata';
import {
  EntityFormContext,
  useEntryProvider,
  useTrack,
  useUpdateEntity,
} from 'imddata';
import memoize from 'memoize-one';
import MaskedInput from 'react-text-mask';
import {
  Button,
  ComponentIcons,
  FieldGroup,
  LoadingIndicator,
  HelpWindowContext,
  Centered,
  createInputWithStyles,
} from 'imdui';
import moment from 'moment';
import {
  EnabledField,
  FieldUpdatable,
  GenreSelectField,
  NewInputField,
  EnabledFieldGroup,
} from '../../../fields';
import { ContributorJoinField } from '../../ContributorJoin';
import { PerformerJoinField } from '../../PerformerJoin';
import { PublisherJoinField } from '../../PublisherJoin';
import { TrackArtistJoinField } from '../../TrackArtistJoin';
import NamesField from '../../NamesField';
import LabelTextField from '../../LabelTextField';
import { getTrackIsrc, createSelector, formatOffset } from '../../../helpers';
import { validate, createWarn } from '../shared';
import AudioLanguageField from '../shared/AudioLanguageField';
import { AudioField } from './components';
import type { JoinTagValue } from '../../JoinTags/types';
import { useFeature } from 'imdfeature';

const UPDATE_QUERY = { with: 'artists,publishers,contributors' };

const TRANSLATABLE_FIELDS = [
  {
    name: 'title',
    label: 'track-title',
    longHelp: 'track-helptext-title',
    testId: 'track-title',
  },
  {
    name: 'version',
    label: 'track-version',
    longHelp: 'track-helptext-version',
    testId: 'track-version',
  },
];

const WORK_TITLE_FIELD = {
  name: 'workTitle',
  label: 'track-work-title',
  shortHelp: 'track-helptext-short-work-title',
  longHelp: 'track-helptext-work-title',
  testId: 'track-work-title',
};

const SelectHasNoPublishers = createSelector('hasNoPublishers');

const ISRC_MASK = [
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const StyledMaskedInput = createInputWithStyles(MaskedInput);

const renderIsrcInput = (props: any) => (
  <StyledMaskedInput
    {...props}
    placeholder="CCXXX0000000"
    placeholderChar="_"
    guide={true}
    mask={ISRC_MASK}
  />
);

type EditableFields = ReturnType<typeof useTrack>['editableFields'];
type ConnectedProps = {
  form: string;
  languageId: string;
  trackId: number;
  shouldTouch?: boolean;
  registeredIsrc: boolean;
  requiredFields?: string[];
  audioDuration: number | undefined;
  disabled?: boolean;
  editableFields: EditableFields;
  isClassical: boolean;
  targets: TrackNested['targets'];

  displayedFields?: {
    copyrightText?: boolean;
    publishers?: boolean;
    performers?: boolean;
    audioLanguageId?: boolean;
  };
};

type FormData = any;

function SingleEditForm({
  requiredFields,
  touch,
  shouldTouch,
  change,
  form,
  disabled,
  languageId,
  editableFields,
  audioDuration,
  initialValues,
  registeredIsrc,
  trackId,
  isClassical,
  displayedFields,
}: ConnectedProps & InjectedFormProps<FormData, ConnectedProps>) {
  const { t } = useTranslation();
  const [traditonalTracksEnabled] = useFeature({
    featureKey: 'tradional-enabled',
  });
  const [showPublisherOptions, setShowPublisherOptions] = useState(
    () => initialValues.hasNoPublishers
  );
  const [showContributorOptions, setShowContributorOptions] = useState(
    () =>
      initialValues.hasTraditionalLyricist ||
      initialValues.hasTraditionalComposer
  );
  const { updateEntry: updateTrack } = useUpdateEntity({
    entity: 'tracks',
    query: UPDATE_QUERY,
    id: trackId,
  });
  const openHelpWindow = useContext(HelpWindowContext);
  const showHelpWindow = useCallback(
    (...args: Parameters<typeof openHelpWindow>) =>
      () =>
        openHelpWindow(...args),
    []
  );

  const nameFields = useMemo(() => {
    if (isClassical) {
      return [...TRANSLATABLE_FIELDS, WORK_TITLE_FIELD];
    }
    return TRANSLATABLE_FIELDS;
  }, [isClassical]);

  const handleTrackField = useCallback(
    memoize((key: string) => (event: any, value?: any) => {
      updateTrack(
        {
          formId: form,
          data: { [key]: value },
        },
        { debounce: true }
      );
    }),
    []
  );

  const handleIsrc = (event: any, value: any) => {
    const strippedValue = value ? value.replace(/-/g, '') : value;

    if (strippedValue.length === 12) {
      handleTrackField('customIsrc')(event, strippedValue);
    } else if (strippedValue.length === 0) {
      handleTrackField('customIsrc')(event, strippedValue);
    }
  };

  const handleContributors = (event: any, value: JoinTagValue[] = []) => {
    updateTrack(
      {
        formId: form,
        data: {
          nonPerformingContributors: value,
        },
      },
      { debounce: true }
    );
  };

  const handleNoPublishers = useCallback((event, value) => {
    change('publishers', []);
    updateTrack(
      {
        formId: form,
        data: { hasNoPublishers: value, publishers: [] },
      },
      { debounce: true }
    );
  }, []);

  const handleIsCoverVersion = useCallback((event, value) => {
    updateTrack(
      {
        formId: form,
        data: {
          isCoverVersion: value,
          ...(value === true
            ? {
                hasTraditionalComposer: false,
                hasTraditionalLyricist: false,
              }
            : {}),
        },
      },
      { debounce: true }
    );
    if (value === true) {
      change('hasTraditionalLyricist', false);
      change('hasTraditionalComposer', false);
    }
  }, []);

  const handleUpdate = useCallback(
    (data) => {
      updateTrack(
        {
          formId: form,
          data,
        },
        { debounce: true }
      );
    },
    [updateTrack, form]
  );

  const entityFormValue = useMemo<React.ContextType<typeof EntityFormContext>>(
    () => ({ entity: 'tracks', id: trackId }),
    [trackId]
  );

  // Commented out not to advance the updatedAt for shouldTouch to trigger
  // useEffect(() => {
  //   return () => {
  //     handleUpdate({});
  //   };
  // }, []);

  const selectFormValues = useMemo(() => formValueSelector(form), [form]);

  const isCoverVersion = useSelector((state: ReduxState) =>
    selectFormValues(state, 'isCoverVersion')
  );

  const handlePrelistening = useCallback((v) => {
    const [minutes, seconds] = v.split(':');
    const value = Number(minutes) * 60 + Number(seconds);
    if (isNaN(value) || !audioDuration || audioDuration < 60) return;

    const offset = value > audioDuration - 60 ? audioDuration - 60 : value;

    handleTrackField('prelisteningOffset')(null, offset);
    change('prelisteningOffset', formatOffset(offset));
  }, []);

  const prelisteningOffset = useSelector((state: ReduxState) =>
    selectFormValues(state, 'prelisteningOffset')
  );

  const prelisteningOffsetRef = useRef(prelisteningOffset);

  useEffect(() => {
    if (prelisteningOffsetRef.current !== prelisteningOffset) {
      prelisteningOffsetRef.current = prelisteningOffset;
      handlePrelistening(prelisteningOffset);
    }
  }, [prelisteningOffset]);

  useEffect(() => {
    if (requiredFields && shouldTouch)
      [...requiredFields].forEach((f) => touch(f));
  }, []);

  useEffect(() => {
    if (isCoverVersion && shouldTouch) {
      touch('publishers');

      if (requiredFields && shouldTouch) {
        [...requiredFields].forEach((f) => touch(f));
      }
    }
  }, [isCoverVersion]);

  const performersSection = (
    <>
      <FieldUpdatable
        name="performers"
        label={t('performers-details')}
        component={LabelTextField}
        onClickHelp={showHelpWindow(
          t('track-helptext-short-performers'),
          t('track-helptext-performers')
        )}
      />

      <FieldUpdatable
        name="performers"
        data-test-id="performers-jointag"
        disabled={!editableFields.performers}
        component={PerformerJoinField}
        onChange={handleTrackField('performers')}
        testId="performers"
        languageId={languageId}
      />
    </>
  );

  return (
    <EntityFormContext.Provider value={entityFormValue}>
      <FieldGroup>
        <FieldUpdatable
          name="isInstrumental"
          disabled={!editableFields.contributors}
          label={t('instrumental')}
          component={EnabledField}
          style={{ marginBottom: '8px' }}
          onChange={handleTrackField('isInstrumental')}
        />
        <FieldUpdatable
          name="isCoverVersion"
          testId="isCoverVersion"
          disabled={!editableFields.isCoverVersion}
          label={t('cover-version')}
          component={EnabledField}
          style={{ marginBottom: '8px' }}
          onChange={handleIsCoverVersion}
        />
        <FieldUpdatable
          name="isLiveVersion"
          testId="isLiveVersion"
          disabled={!editableFields.isLiveVersion}
          label={t('live-version')}
          component={EnabledField}
          onChange={handleTrackField('isLiveVersion')}
        />
      </FieldGroup>

      <FieldGroup data-test-id="track-audio-section">
        <FieldUpdatable
          entityFieldName="audios"
          name="uploadStatus"
          trackId={trackId}
          audioDuration={audioDuration}
          handleTrackField={handleTrackField}
          disabled={!editableFields.audioUpload}
          onClickHelperButton={showHelpWindow(
            t('track-helptext-short-audio-upload', {
              context: 'window-title',
            }),
            t('track-helptext-audio-upload')
          )}
          component={AudioField}
        />
      </FieldGroup>

      <FieldGroup>
        <FieldArray
          name="names"
          entity="trackNames"
          languageLabelPrefix="track"
          // @ts-ignore
          component={NamesField}
          fieldsData={nameFields}
          disabled={disabled}
          onChange={handleUpdate}
        />
      </FieldGroup>
      {displayedFields?.audioLanguageId ? (
        <FieldGroup>
          <AudioLanguageField
            requiredFields={requiredFields}
            form={form}
            showHelpWindow={showHelpWindow}
            handleTrackField={handleTrackField}
          />
        </FieldGroup>
      ) : null}

      {requiredFields?.includes('copyrightText') ||
      displayedFields?.copyrightText ? (
        <FieldGroup>
          <FieldUpdatable
            name="copyrightText"
            // It exists
            // @ts-ignore
            testId="copyright-text"
            label={t('copyright-text')}
            disabled={!editableFields.copyrightText}
            onChange={handleTrackField('copyrightText')}
            component={NewInputField}
          />
        </FieldGroup>
      ) : null}

      <FieldGroup>
        <FieldUpdatable
          name="genreId"
          testId="genreId"
          disabled={!editableFields.genreId}
          onChange={handleTrackField('genreId')}
          component={GenreSelectField}
        />
      </FieldGroup>

      <FieldGroup>
        <FieldUpdatable
          name="artists"
          label={t('artists-details')}
          onClickHelp={showHelpWindow(
            t('artists'),
            t('track-helptext-artists')
          )}
          component={LabelTextField}
        />

        <FieldUpdatable
          name="artists"
          data-test-id="artists-jointag"
          testId="artists"
          disabled={!editableFields.artists}
          component={TrackArtistJoinField}
          onChange={handleTrackField('artists')}
          languageId={languageId}
        />
      </FieldGroup>
      <FieldGroup>
        <FieldUpdatable
          name="contributors"
          entityFieldName="nonPerformingContributors"
          label={t('contributors-details')}
          onClickHelp={showHelpWindow(
            t('track-helptext-short-contributors'),
            t('track-helptext-contributors')
          )}
          component={LabelTextField}
        />

        <FieldUpdatable
          name="contributors"
          entityFieldName="nonPerformingContributors"
          data-test-id="contributors-jointag"
          component={ContributorJoinField}
          onChange={handleContributors}
          disabled={!editableFields.contributors}
          languageId={languageId}
          testId="contributors"
          extraButton={
            <Button
              style={{
                flex: 'none',
                display:
                  traditonalTracksEnabled || showContributorOptions
                    ? 'block'
                    : 'none',
              }}
              text={t('options')}
              icon={
                !showContributorOptions
                  ? ComponentIcons.ChevronDown
                  : ComponentIcons.ChevronUp
              }
              onClick={() => {
                setShowContributorOptions(!showContributorOptions);
              }}
            />
          }
        >
          {showContributorOptions && (
            <EnabledFieldGroup style={{ marginTop: '16px' }}>
              <FieldUpdatable
                name="hasTraditionalComposer"
                disabled={!editableFields.contributors || isCoverVersion}
                label={t('traditional-composer')}
                description={t('traditional-composer-description', {
                  context: isCoverVersion ? 'cover-version' : '',
                })}
                component={EnabledField}
                onChange={handleTrackField('hasTraditionalComposer')}
              />
              <FieldUpdatable
                name="hasTraditionalLyricist"
                disabled={!editableFields.contributors || isCoverVersion}
                label={t('traditional-lyricist')}
                description={t('traditional-lyricist-description', {
                  context: isCoverVersion ? 'cover-version' : '',
                })}
                component={EnabledField}
                onChange={handleTrackField('hasTraditionalLyricist')}
              />
            </EnabledFieldGroup>
          )}
        </FieldUpdatable>
      </FieldGroup>

      {requiredFields?.includes('performers') || displayedFields?.performers ? (
        <FieldGroup>{performersSection}</FieldGroup>
      ) : null}

      {isCoverVersion || displayedFields?.publishers ? (
        <FieldGroup>
          <FieldUpdatable
            name="publishers"
            label={t('publishers-details')}
            onClickHelp={showHelpWindow(
              t('publishers-details'),
              t('track-helptext-publishers')
            )}
            component={LabelTextField}
          />

          <SelectHasNoPublishers form={form}>
            {(checked: boolean) => (
              <FieldUpdatable
                name="publishers"
                data-test-id="publishers-jointag"
                component={PublisherJoinField}
                onChange={handleTrackField('publishers')}
                disabled={!editableFields.publishers || checked}
                languageId={languageId}
                testId="publishers"
                extraButton={
                  <Button
                    size="small"
                    position="center"
                    text={t('optional')}
                    icon={
                      !showPublisherOptions
                        ? ComponentIcons.ChevronDown
                        : ComponentIcons.ChevronUp
                    }
                    onClick={() => {
                      setShowPublisherOptions(!showPublisherOptions);
                    }}
                  />
                }
              >
                {showPublisherOptions && (
                  <FieldUpdatable
                    name="hasNoPublishers"
                    style={{ marginTop: '16px' }}
                    type="checkbox"
                    label={t('no-publisher-available')}
                    description={t('no-publisher-available-description')}
                    component={EnabledField}
                    disabled={!editableFields.publishers}
                    // @ts-ignore
                    onChange={handleNoPublishers}
                  />
                )}
              </FieldUpdatable>
            )}
          </SelectHasNoPublishers>
        </FieldGroup>
      ) : null}

      <FieldGroup>
        <FieldUpdatable
          name="hasExplicitContent"
          disabled={!editableFields.hasExplicitContent}
          label={t('explicit-content')}
          component={EnabledField}
          onChange={handleTrackField('hasExplicitContent')}
          style={{ marginBottom: '24px' }}
        />

        <FieldUpdatable
          name="customIsrc"
          component={NewInputField}
          // @ts-ignore
          onChange={handleIsrc}
          disabled={!editableFields.customIsrc || registeredIsrc}
          label={t('isrc')}
          helperText={t('track-helptext-short-isrc')}
          renderInput={renderIsrcInput}
          onClickHelp={showHelpWindow(t('isrc'), t('track-helptext-isrc'))}
        />
      </FieldGroup>
    </EntityFormContext.Provider>
  );
}

const TrackEditReduxForm = reduxForm<FormData, ConnectedProps>({
  shouldError: ({ values, nextProps, props, initialRender, ...args }) => {
    return (
      (nextProps &&
        (props.targets !== nextProps.targets ||
          props.initialized !== nextProps.initialized)) ||
      defaultShouldError({ values, nextProps, props, initialRender, ...args })
    );
  },
  persistentSubmitErrors: true,
  // enableReinitialize: true,
  // keepDirtyOnReinitialize: true,
})(SingleEditForm);

const useValues = (entry: TrackNested | null): FormData | undefined => {
  return useMemo(() => {
    if (!entry) {
      return undefined;
    }

    return {
      defaultLanguageId: entry.defaultLanguageId,
      genreId: entry.genreId,
      revenueSplits: entry.revenueSplits,
      isInstrumental: entry.isInstrumental,
      isCoverVersion: entry.isCoverVersion,
      isLiveVersion: entry.isLiveVersion,
      hasExplicitContent: entry.hasExplicitContent,
      hasNoPublishers: entry.hasNoPublishers,
      uploadStatus: entry.uploadStatus,
      customIsrc: getTrackIsrc(entry),
      copyrightText: entry.copyrightText,
      prelisteningOffset: formatOffset(entry.prelisteningOffset),
      audioLanguageId: entry.audioLanguageId,
      names: entry.namesNormalized?.sort(
        (a, b) =>
          new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
      ),
      performers:
        entry.performers &&
        entry.performers.map(({ contributorId, role }) => ({
          contributorId,
          role,
        })),
      artists:
        entry.artists &&
        entry.artists.map(({ artistId, role }) => ({ artistId, role })),
      publishers:
        entry.publishers &&
        entry.publishers.map(({ publisherId }) => ({
          publisherId,
        })),
      hasTraditionalLyricist: entry.hasTraditionalLyricist,
      hasTraditionalComposer: entry.hasTraditionalComposer,
      contributors: entry.nonPerformingContributors.map(
        ({ contributorId, role }) => ({
          contributorId,
          role,
        })
      ),
    };
  }, [entry]);
};

const TrackEditReduxFormWithData: React.FC<{
  trackId: ConnectedProps['trackId'];
  form: string;
  // warn?: ConfigProps<FormData, ConnectedProps>['warn'];
  additionalValidation?: (...args: unknown[]) => Record<string, any>;
  requiredFields?: string[];
  displayedFields?: {
    copyrightText?: boolean;
    publishers?: boolean;
    performers?: boolean;
    audioLanguageId?: boolean;
  };
}> = ({ trackId, form, ...props }) => {
  const { editableFields, entry } = useTrack({ id: trackId });
  const selectFormValues = useMemo(() => formValueSelector(form), [form]);
  const genreId = useSelector((state: ReduxState) =>
    selectFormValues(state, 'genreId')
  );
  const genre = useEntryProvider<EntityModels.Genre>({
    entity: 'genres',
    id: genreId,
  });
  const isClassical = genre?.handler === 'classical';
  const initialValues = useValues(entry);
  const { t } = useTranslation();
  const warn = useMemo(() => createWarn(t), [t]);

  if (!initialValues || !entry) {
    return (
      <Centered>
        <LoadingIndicator size="large" />
      </Centered>
    );
  }

  const shouldTouch =
    moment(entry.updatedAt).diff(entry.createdAt, 'millisecond') > 2500;

  return (
    <TrackEditReduxForm
      trackId={trackId}
      shouldTouch={shouldTouch}
      form={form}
      key={form}
      warn={warn}
      validate={validate}
      languageId={entry.defaultLanguageId}
      initialValues={initialValues}
      audioDuration={entry.duration || entry.localAudioDuration}
      registeredIsrc={entry.isrcs && !!entry.isrcs.length}
      isClassical={isClassical}
      editableFields={editableFields}
      {...props}
      targets={entry.targets}
    />
  );
};

export default TrackEditReduxFormWithData;
